import React, { useState, useEffect } from 'react'
import { PrismicRichText } from '@prismicio/react'
import { StaticQuery, graphql } from 'gatsby'

import SliceRenderer from '../components/ContentBlocks/SliceRenderer'
import { formatDate } from '../util/timeDateUtils'
import * as Styled from './styles/AdvanceArticleContent.styles'
import RecentAdvanceArticles from '../components/ContentBlocks/RecentAdvanceArticles'
import { BodyCopy } from '../components/ContentBlocks/styles/BodyContentBlock.styles'
import { sliceBottomPadding } from '../util/helper'
import AdSlot from './AdSlot'

const ADVANCE_ARTICLE_GOOGLE_ADS_QUERY = graphql`
  {
    prismicGeneralSiteSettings {
      data {
        advance_article_bottom_line_google_ad {
          document {
            ... on PrismicGoogleAdTag {
              id
              data {
                ad_slot_name
              }
            }
          }
        }
        advanced_article_interstitial_ad {
          document {
            ... on PrismicGoogleAdTag {
              id
              data {
                ad_slot_name
              }
            }
          }
        }
        advance_article_sidebar_google_ad_one {
          document {
            ... on PrismicGoogleAdTag {
              id
              data {
                ad_slot_name
              }
            }
          }
        }
        advance_article_sidebar_google_ad_two {
          document {
            ... on PrismicGoogleAdTag {
              id
              data {
                ad_slot_name
              }
            }
          }
        }
        advance_article_top_line_google_ad {
          document {
            ... on PrismicGoogleAdTag {
              id
              data {
                ad_slot_name
              }
            }
          }
        }
      }
    }
  }
`

const dateString = node => {
  if (node.publish_date) {
    let formattedDate = formatDate(node.publish_date)
    if (node.add_last_updated_date_label) {
      formattedDate = `Last updated: ${formattedDate}`
    }
    return formattedDate
  }
  return null
}

const AdvanceArticleContent = ({ doc }) => {
  const [slices, setSlices] = useState([])
  const [articleBodyText, setArticleBodyText] = useState(null)
  const [bottomPaddingAdjustment, setBottomPaddingAdjustment] = useState(null)

  useEffect(() => {
    if (!doc?.data?.body) return
    setSlices([...doc.data.body])
  }, [doc])

  useEffect(() => {
    if (!slices) return
    const firstBodyText = slices
      .splice(
        slices.findIndex(block => block.slice_type === 'body_content'),
        1
      )
      .slice(0, 1)
      ?.pop()
    setArticleBodyText(firstBodyText?.primary?.body_copy?.richText)
    const bottomPaddingAdjustment =
      firstBodyText?.primary?.bottom_padding_adjustment ?? null
    setBottomPaddingAdjustment(sliceBottomPadding(bottomPaddingAdjustment))
  }, [slices])

  return (
    <StaticQuery
      query={`${ADVANCE_ARTICLE_GOOGLE_ADS_QUERY}`}
      render={data => {
        const node = data.prismicGeneralSiteSettings.data

        return (
          <>
            {/* <pre>{JSON.stringify(slices, false, 2)}</pre> */}
            <Styled.PageGrid bottomPaddingAdjustment={bottomPaddingAdjustment}>
              <Styled.PageColumnGrid>
                <Styled.ArticleColumn>
                  <div style={{ marginBottom: '42px ' }}>
                    <AdSlot
                      divId={`div-${node.advance_article_top_line_google_ad?.document?.data?.ad_slot_name}`}
                    />
                  </div>
                  <Styled.ArticleDateField>
                    {dateString(doc.data)}
                  </Styled.ArticleDateField>
                  <BodyCopy>
                    <PrismicRichText field={articleBodyText} />
                  </BodyCopy>
                </Styled.ArticleColumn>

                <Styled.RecentArticlesColumn>
                  <RecentAdvanceArticles />
                  <div style={{ marginTop: '42px' }}>
                    <AdSlot
                      divId={`div-${node.advance_article_sidebar_google_ad_one?.document?.data?.ad_slot_name}`}
                    />
                  </div>
                  <div style={{ marginTop: '42px' }}>
                    {/* <AdSlot
                      divId={`div-${node.advance_article_sidebar_google_ad_two?.document?.data?.ad_slot_name}`}
                    /> */}
                  </div>
                </Styled.RecentArticlesColumn>
              </Styled.PageColumnGrid>
            </Styled.PageGrid>

            {slices &&
              slices.map(block => (
                <SliceRenderer key={block.id} block={block} />
              ))}

            <AdSlot
              divId={`div-${node.advance_article_bottom_line_google_ad?.document?.data?.ad_slot_name}`}
            />
          </>
        )
      }}
    />
  )
}

export default AdvanceArticleContent
